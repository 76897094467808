<template>
  <div class="date-time-picker">
    <div class="field-label">
      {{ placeholder }}
    </div>
    <v-layout wrap id="fields-content">
      <v-flex xs12 sm6>
        <DatePicker
          ref="datePicker"
          :required="required"
          :value="paramValue"
          :minDate="minAllowedDateTime"
          :maxDate="maxAllowedDateTime"
          @input="onDateChange"
        ></DatePicker>
      </v-flex>
      <v-flex xs12 sm6>
        <TimePicker
          ref="timePicker"
          :value="paramValue"
          :required="required"
          :allowedMinutes="allowedMinutes"
          :minTime="isTodayDateSelected ? minAllowedDateTime : null"
          :maxTime="isTodayDateSelected ? maxAllowedDateTime : null"
          @input="onTimeChange"
        ></TimePicker>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import moment from "@/plugins/moment";

import DatePicker from "@/components/Shared/DatePicker";
import TimePicker from "@/components/Shared/TimePicker";

import abstractField from "@/utils/AbstractField";

export default {
  name: "DateTimePicker",
  components: { DatePicker, TimePicker },
  mixins: [abstractField],
  props: { paramValue: String, mode: Number, attribute: Object },
  data() {
    return {
      maxAllowedDateTime:
        this.attribute && this.attribute.allowDateType === "PAST"
          ? moment()
          : null,
      minAllowedDateTime:
        this.attribute && this.attribute.allowDateType === "FUTURE"
          ? moment()
          : null,
    };
  },
  computed: {
    minute() {
      return this.paramValue ? moment(this.paramValue).minute() : 0;
    },
    hour() {
      return this.paramValue ? moment(this.paramValue).hour() : 0;
    },
    day() {
      return this.paramValue ? moment(this.paramValue).date() : moment().date();
    },
    month() {
      return this.paramValue
        ? moment(this.paramValue).month()
        : moment().month();
    },
    year() {
      return this.paramValue ? moment(this.paramValue).year() : moment().year();
    },
    allowedMinutes() {
      if (this.mode === 15) {
        return [0, 15, 30, 45];
      } else if (this.mode === 30) {
        return [0, 30];
      }

      return undefined;
    },
    isTodayDateSelected() {
      return moment(this.paramValue).isSame(moment(), "day");
    },
  },
  methods: {
    onDateChange(newDate) {
      let date = null;

      if (newDate) {
        date = moment(newDate)
          .set("hour", this.hour)
          .set("minute", this.minute)
          .set("second", this.withSeconds ? this.second : 0)
          .format("YYYY-MM-DDTHH:mm:ss");
      }

      this.$emit("onChange", {
        id: this.id,
        value: date,
      });
    },
    onTimeChange(newTime) {
      const date = newTime
        ? moment(newTime)
            .set("year", this.year)
            .set("month", this.month)
            .set("date", this.day)
            .format("YYYY-MM-DDTHH:mm:ss")
        : null;

      this.$emit("onChange", {
        id: this.id,
        value: date,
      });
    },
    checkIsFieldValid() {
      //nadpisane z mixina AbstractFields
      this.$refs["datePicker"].$v.$touch();
      this.$refs["timePicker"].$v.$touch();
      return (
        !this.$refs["datePicker"].$v.$invalid &&
        !this.$refs["timePicker"].$v.$invalid
      );
    },
  },
};
</script>

<style scoped lang="scss">
.date-time-picker #fields-content {
  margin-top: -20px;
}
</style>
